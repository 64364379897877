
import Vue from "vue"
import { mapGetters } from "vuex"
import { AINOBLOCKS_ACCORDION_FAQ_BLOCK } from "~/constants/blocks"
import { GET_BOOKINGS_COPY, GET_POSTAL_CODE } from "~/store/booking-insights/getters"
import { processBlock } from "~/scripts/useful-functions"
import { GET_PHONE_NUMBER_VALUE } from "~/store/phone-number/getters"

export default Vue.extend({
  name: "AvFaqs",

  props: {
    block: {
      required: true,
      type: Object as () => Block
    },
    lazy: {
      type: Boolean,
      default: true
    },
    dynamicContent: {
      type: Boolean
    }
  },

  data() {
    return {
      AINOBLOCKS_ACCORDION_FAQ_BLOCK,
      activeFaq: null,
      blockToUse: { ...this.block },
      replacementData: {} as Dictionary
    }
  },

  watch: {
    headerCopy: {
      immediate: true,
      handler(newValue: AvBookingsCopy) {
        if (newValue && this.dynamicContent) {
          const {
            average_home_move_cost,
            average_four_bed_home_move_cost,
            average_savings,
            faq_average_cost,
            faq_move_near_me,
            location,
            short_postcode
          } = newValue
          this.replacementData = {
            ...this.replacementData,
            average_home_move_cost,
            average_four_bed_home_move_cost,
            average_savings,
            faq_average_cost,
            faq_move_near_me,
            location,
            short_postcode
          }
          this.replaceDynamicContent()
        }
      }
    },
    phoneNumber: {
      immediate: true,
      handler(newValue: PhoneNumberPair) {
        if (newValue && newValue.formatted && this.dynamicContent) {
          const { formatted: phone_number } = newValue
          this.replacementData = {
            ...this.replacementData,
            phone_number
          }
          this.replaceDynamicContent()
        }
      }
    }
  },
  computed: {
    ...mapGetters("booking-insights", {
      postalCode: GET_POSTAL_CODE,
      headerCopy: GET_BOOKINGS_COPY
    }),
    ...mapGetters("phone-number", {
      phoneNumber: GET_PHONE_NUMBER_VALUE
    })
  },

  methods: {
    setActiveFaq(faqIndexClicked) {
      this.activeFaq = faqIndexClicked === this.activeFaq ? null : faqIndexClicked
    },
    replaceDynamicContent() {
      this.$nextTick(() => {
        this.blockToUse = processBlock(this.block, this.replacementData)
      })
    }
  }
})
