export const BREADCRUMBS = "av-breadcrumbs"
export const BREADCRUMB = "diy-breadcrumbs"
export const INTERNAL_REVIEWS = "av-internal-reviews"
export const INTERNAL_REVIEW_SECTION = "av-internal-review-section"
export const COMPARISON_TABLE_SECTION = "comparison-table-section"
export const COMPARISON_TABLE_REVERSE = "comparison-table-reverse"
export const FAQ_SECTION = "faq-section"
export const FAQ_REVERSE = "faq-section-reverse"
export const RECENT_MOVES_SECTION = "recent-moves-section"
export const COMPARISON_TABLE_ITEM = "comparison-table-item"
export const ICON_TABLE = "av-icon-table"
export const LINK_TABLE = "av-link-table"
export const CHECK_LIST = "av-check-list"
export const LOCATION_FORM = "location-form"
export const REMOVALS_FORM = "location-and-property-form"
export const OFFICE_FORM = "office-form"
export const CTA_FORM = "cta-form"
export const LOCATION_FORM_EXTENSION = "lf-extension"
export const VALIDATION = "av-validation"
export const NO_BUTTONS = "av-no-buttons"
export const NO_PIPS = "av-no-pips"
export const AVAILABILITY_REMARK = "av-availability-remark"
export const ITEM_IDENTIFIER = "av-item-identifier"

export const CALL_US = "av-call-us"
export const RETRIEVE_QUOTE = "av-retrieve-quote"
export const FAQS_WRAPPER = "faqs-wrapper"
export const TRUSTPILOT_REVIEWS = "group__trustpilot_reviews"
export const TRUSTPILOT_CAROUSEL = "av-trustpilot-carousel"
export const CAROUSEL_SWIPER = "av-carousel"
export const EXPERIMENT = "av-experiment"

// 👇 Remove me once WordPress no longer has me
export const AV_FAQ_OLD = "av-f-a-q"
export const AV_FAQ = "av-faq"
export const AV_DYNAMIC_FAQ_CONTENT = "av-dynamic-faqs"
export const AV_DYNAMIC_CONTENT = "av-dynamic-content"
export const PAGE_TILE_NAVIGATION = "group__page_tile_navigation"
export const PAGE_TILE_NAVIGATION_FULL_IMAGE = "group__page_tile_navigation_full_image"
export const HEADER = "rebrand-header"
export const HEADER_NARROW_LHS = "narrow-left-hand-side"
export const IMAGE_DIALOG = "image-dialog"
export const PRICE_BANNER = "price-banner"
export const IMAGE_OVERLAY = "av-image-overlay"
export const VALUE_ADD_SECTION = "av-value-add"
export const VALUE_ADD_REVERSE = "av-value-add-reverse"
export const OTHER_SERVICES_SECTION = "av-other-services"
export const OTHER_SERVICES_REVERSE = "av-other-services-reverse"
export const RESPONSIVE_IMAGE_SET = "av-responsive-image-set"
export const IMAGE_TEXT_SECTION = "av-image-text"
export const LINK_PHONE = "av-link-phone"
export const LINK_PHONE_LINK_ONLY = "av-link-phone-link-only"
export const HEADER_CARDS = "av-header-cards"
export const HOME_PAGE_HEADER = "homepage-header"
export const CATEGORY_CARD = "category-card"
export const TRUSTPILOT_WIDGET = "trustpilot-widget"
export const MAPBOX_LOCAL_MOVES_MAP = "mapbox-local-moves-map"
export const MAP_PANEL_CTAS = "map-panel-ctas"
export const MEDIA_TEXT_RESPONSIVE = "av-media-text-responsive"
export const AV_SAVINGS = "av-savings"
export const AV_LOCAL_SUMMARY = "av-local-summary"
export const SERVICE_HIGHLIGHT = "av-service-highlight"
export const GO_LOCAL_HEADER = "av-go-local-header"
export const GO_LOCAL_ACCOUNT_MANAGER = "av-go-local-account-manager"
export const GO_LOCAL_REVIEWS = "av-go-local-reviews"
export const AV_BACKGROUND_IMAGE = "av-background-image"
export const AV_WHATSAPP_BUTTON = "av-whatsapp-button"

export const CORE_MEDIA_TEXT = "CORE_MEDIA_TEXT"
export const CORE_BUTTON = "CORE_BUTTON"
export const CORE_NEXTPAGE = "CORE_NEXTPAGE"
export const CORE_COVER = "CORE_COVER"
export const CORE_IMAGE = "CORE_IMAGE"
export const CORE_GALLERY = "CORE_GALLERY"
export const CORE_TABLE = "CORE_TABLE"
export const CORE_QUERY = "CORE_QUERY"
export const CORE_HTML = "CORE_HTML"
export const CORE_COLUMN = "CORE_COLUMN"
export const CORE_HEADING = "CORE_HEADING"
export const CORE_PARAGRAPH = "CORE_PARAGRAPH"
export const CORE_GROUP = "CORE_GROUP"
export const CORE_SEPARATOR = "CORE_SEPARATOR"
export const DEFAULT_HERO = "DEFAULT_HERO"
export const YOAST_SEO_BREADCRUMBS = "YOAST_SEO_BREADCRUMBS"
export const AINOBLOCKS_ACCORDION_FAQ_BLOCK = "AINOBLOCKS_ACCORDION_FAQ_BLOCK"
